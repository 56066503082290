import { useApiMiddleware } from "./useApiMiddlware";

export const useApi = () => {
    const apiMiddleware = useApiMiddleware()
  return {
    customRequest: (url, options, callback) => apiMiddleware(url, options, callback),
    
    // get: (url) => apiMiddleware(url, {method: "GET"}),
    // post: (url, body) => apiMiddleware(url, { method: "POST", body: JSON.stringify(body) }),
    // put: (url, body) => apiMiddleware(url, { method: "PUT", body: JSON.stringify(body) }),
    // delete: (url) => apiMiddleware(url, { method: "DELETE" }),
  };
};