// File: src/hooks/useApiMiddleware.js
import { useAuth } from "../providers/AuthProvider";
import { useRefreshToken } from "./useRefreshToken";

export function useApiMiddleware() {
  const { userState, setUserState } = useAuth();
  const refreshAccessToken = useRefreshToken();

  // This function does the actual "middleware" logic
  const apiMiddleware = async (url, options = {}, callback = null) => {
    // Set default headers
    options.headers = options.headers || {
      "Content-Type": "application/json",
    };
    // Attach authorization token if available
    const token = userState?.token;
    if (token) {
      options.headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      let response = await fetch(url, options);

      // If token is expired, try refreshing
      if (response.status === 401) {
        console.warn("Unauthorized! Refreshing token...");

        const credentials= await refreshAccessToken();
        if (credentials) {
          // Retry the original request with new token
          options.headers["Authorization"] = `Bearer ${credentials.token}`;
          response = await fetch(url, options);
        } else {
          console.error("Session expired. Please log in again.");
          alert("Session expired. Please log in again.");
          response = { error: "Session expired. Please log in again." };
        }
      }
      
      if (callback) {
        callback(response.ok ? response : null);
        if(!response.ok)
          console.log(response)
      }
      

      return response;
    } catch (error) {
      console.error("API Middleware Error:", error);

      const errorResponse = { error: error.message };
      if (callback) {
        callback(null);
      }
      return errorResponse;
    }
  };

  // Return the function so components (or other hooks) can use it
  return apiMiddleware;
}
