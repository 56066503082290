import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Skeleton, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { paperSize, scales } from "../../js/defines";

import BasicMapExport from '../basicMap/BasicMapExport';
import { DescriptionOutlined } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { fileService } from '../../API/fileService';
import jsPDF from 'jspdf';
import { useMap } from '../../context/MapProvider';
import { useSelectedPoiList } from '../../context/SelectedPoiProvider';
import { useServerData } from '../../providers/DataProvider';
import { useTheme } from '@emotion/react';
import { writeArrayBuffer } from 'geotiff';

const ExportButton = (props) => {
  const theme = useTheme();
  const { state } = useSelectedPoiList();
  const { mapData } = useMap();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [gisStyles, setGisStyles] = useState([]);
  const [selectedPois, setSelectedPois] = useState([]);
  const [selectedBlobs, setSelectedBlobs] = useState([]);
  const [layerArr, setLayerArr]= useState([]);
  const [enabledBaseLayer, setEnabledBaseLayer]= useState("");
  const [enabledLayers, setEnabledLayers]= useState([]);
  const [selectedStyle, setSelectedStyle] = useState([]);
  const [mapConfig, setMapConfig] = useState([]);
  const [currentMapPos, setCurrentMapPos] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);

  const [paperFormat, setPaperFormat] = useState("A4");
  const [paperOrientation, setPaperOrientation] = useState("portrait");
  const [exportFormat, setExportFormat] = useState("pdf");
  const [mapScale, setMapScale] = useState("custom");
  const [clusterIcons, setClusterIcons] = useState(mapConfig[props.selectedPoiConfig?.uniqueKey]?.noCluster ?? true);
  const [showNorthArrow, setShowNorthArrow] = useState(false);
  const [showFileName, setShowFileName] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [showPoiData, setShowPoiData] = useState(false);
  const [outputName, setOutputName] = useState(props.selectedPoiConfig != null ? props.selectedPoiConfig.tableName : "");

  const [isRendering, setIsRendering] = useState(true);
  const [exportActive, setExportActive] = useState(false);
  const [scaleTooltipActive, setScaleTooltipActive] = useState(false);

  function onMapPositionChanged(pos) {}

  function onGeometryChanged() {}

  function handleDialogOpen() {
    setGisStyles(mapData.getGisStylesFunction());
    setSelectedPois(mapData.getSelectedPoisFunction());
    setSelectedBlobs(mapData.getSelectedBlobsFunction());
    setLayerArr(mapData.getLayerArrFunction());
    setEnabledBaseLayer(mapData.getEnabledBaseLayerFunction());
    setEnabledLayers(mapData.getEnabledLayersFunction());
    setSelectedStyle(mapData.getSelectedStyleFunction());
    setMapConfig(mapData.getMapConfigFunction());
    setCurrentMapPos(mapData.getMapPosFunction());
    setDialogOpen(true);
    setExportActive(false);
  }

  const handleFileExport = async () => {
    setExportActive(true);

    if(!state?.data){
      alert("Select Poi")
      setExportActive(false);
      return
    }
    let mappedPoiArray = state?.data?.map((poiObj) => {
      let newObj = {};
      /*Object.entries(poiObj.poi).forEach(([key, value]) => {
        let label = state.configs?.rows?.find(item=> item.id === key)?.label
          newObj[label?? key] = value;
      });*/
      return {
        poi: {
          ...newObj, 
        },
        std: poiObj.std
      }
    })

    try {

      let image = await (mapData?.exportFunction && mapData?.exportFunction(size, 150, sc, paperOrientation));

      //Save options
      switch(selectedTab) {
        case 0:
          switch(exportFormat) {

            case "geotiff":
              let currentExportMapPos = mapData?.exportFunction && mapData?.getExportMapPosFunction();
              let distance = currentExportMapPos.bounds[2] - currentExportMapPos.bounds[0]
              let modelPixelScale = (256/mapWidth) / (256/distance);
              getPixelArrayFromImage(image).then(pixelArray => {
                if (pixelArray) {
                  const metadata = {
                    GeographicTypeGeoKey: 4326, //WGS84
                    ModelPixelScale: [modelPixelScale, modelPixelScale, 0],
                    ModelTiepoint: [0, 0, 0, currentExportMapPos.bounds[0], currentExportMapPos.bounds[3], 0],
                    GeoAsciiParams: "WGS 84 / Pseudo-Mercator|WGS 84|\u0000",
                    GTModelTypeGeoKey: 1,
                    GTRasterTypeGeoKey: 1,
                    GeogCitationGeoKey: "WGS 84",
                    ProjectedCSTypeGeoKey: 3857,
                    width: mapWidth,
                    height: mapHeight,
                    SamplesPerPixel: 4,
                    PhotometricInterpretation: 2
                  };
                  //Create geoTIFF
                  const arrayBuffer = writeArrayBuffer(pixelArray, metadata);
                  //Save file
                  saveFile(arrayBuffer, (outputName != "" ? outputName : "geoTiff") + ".tiff", "image/tiff");
                  setExportActive(false);
                }
              });
              break;

            case "png":
              let convBlob2 = dataURItoBlob(image);
              const downloadUrl = URL.createObjectURL(convBlob2);
              const a = document.createElement('a');
              a.href = downloadUrl;
              a.download = outputName != "" ? outputName : "Karte";
              document.body.appendChild(a);
              a.click();
              setExportActive(false);
              break;

            case "pdf":
              const pdf = new jsPDF(paperOrientation, undefined, paperFormat);
              pdf.addImage(image, 'jpeg', 0, 0, paperOrientation == "portrait" ? size[0] : size[1], paperOrientation == "portrait" ? size[1] : size[0]);
              pdf.save((outputName != "" ? outputName : "Karte") + '.pdf');
              setExportActive(false);
              break;

          }

          setExportActive(false);
          return;

        case 1:
            setExportActive(false);
            switch(exportFormat) {
              case "csv":
                props.dataGridRef.current.exportDataAsCsv({fileName: outputName}, );
                setExportActive(false);
                break;
              
              case "excel":
                props.dataGridRef.current.exportDataAsExcel({fileName: outputName});
                setExportActive(false);
                break;
            }
            return;

        case 2:
          switch(exportFormat) {
            case "docx":
              await fileService.generateTestDocument2({companyInfo: props.userSettings.companyInfo ? props.userSettings.companyInfo : {}, poiData: state?.data, poiArray: showPoiData ? mappedPoiArray : [], image, outputName});
              setExportActive(false);
              break;
          }

          setExportActive(false);
          return;
      }

    } catch(exception) {
      setExportActive(false);
    }
  }

  function loadImageToCanvas(imageSrc) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';  // To avoid CORS issues
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        resolve(canvas);
      };
      img.onerror = reject;
      img.src = imageSrc;
    });
  }

  function getPixelArrayFromCanvas(canvas) {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    return imageData.data;
  }

  async function getPixelArrayFromImage(imageSrc) {
    try {
      const canvas = await loadImageToCanvas(imageSrc);
      const pixelArray = getPixelArrayFromCanvas(canvas);
      return pixelArray;
    } catch (error) {
      console.error('Error loading image:', error);
      return null;
    }
  }

  async function saveFile(plaintext, fileName, fileType) {
    return new Promise((resolve, reject) => {
      const dataView = new DataView(plaintext);
      const blob = new Blob([dataView], { type: fileType });

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
        return resolve();
      } else if (/iPhone|fxios/i.test(navigator.userAgent)) {
        // This method is much slower but createObjectURL
        // is buggy on iOS
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
          if (reader.error) {
            return reject(reader.error);
          }
          if (reader.result) {
            const a = document.createElement('a');
            // @ts-ignore
            a.href = reader.result;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
          }
          resolve();
        });
        reader.readAsDataURL(blob);
      } else {
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(downloadUrl);
        setTimeout(resolve, 100);
      }
    });
  }

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  }

  useEffect(() => {
    if(dialogOpen) setClusterIcons(!mapConfig[props.selectedPoiConfig?.uniqueKey]?.noCluster ?? true);
    setOutputName(props.selectedPoiConfig?.tableName);
  }, [dialogOpen]);

  let size = paperSize[paperFormat];
  var mapWidth = parseInt((paperOrientation == "portrait" ? size[0] : size[1]) * 150 / 25.4);
  var mapHeight = parseInt((paperOrientation == "portrait" ? size[1] : size[0]) * 150 / 25.4);
  var parentWidth = document.getElementById("printParent") ? document.getElementById("printParent").clientWidth : 0;
  var parentHeight = document.getElementById("printParent") ? document.getElementById("printParent").clientHeight : 0;
  var scx = (parentWidth - 100) / mapWidth;
  var scy = (parentHeight - 100) / mapHeight;
  var sc = Math.min(scx, scy, 1);

  return (
    <>
      <Tooltip placement='top' title={"Bericht erstellen"}>
        <span>
          <IconButton size='small' disabled={props.disabled} onClick={() => handleDialogOpen()}><DescriptionOutlined/></IconButton>
        </span>
      </Tooltip>
      <Dialog open={dialogOpen} maxWidth="xl" fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" PaperProps={{ sx: { borderRadius: "16px", height: "90vh", maxHeight: "1000px" } }}>
        <DialogTitle>Bericht erstellen</DialogTitle>
        <DialogContent>
          <div style={{display: "flex", width: "100%", height: "100%", overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column", width: "50%", height: "100%"}}>
              <Typography fontWeight={600} style={{textAlign: "center", userSelect: "none"}}>Einstellungen</Typography>
                <Tabs
                  value={selectedTab}
                  onChange={(e, index) => {
                    setSelectedTab(index);
                    switch(index) {
                      case 0: setExportFormat("pdf"); break;
                      case 1: setExportFormat("excel"); break;
                      case 2: setExportFormat("pdf"); break;
                    }
                  }}
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab label="Karte" />
                  <Tab label="Tabelle" />
                  <Tab label="Dokument" />
                </Tabs>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={selectedTab}
                onChangeIndex={(index) => {
                  setSelectedTab(index);
                  switch(index) {
                    case 0: setExportFormat("pdf"); break;
                    case 1: setExportFormat("excel"); break;
                    case 2: setExportFormat("pdf"); break;
                  }
                }}
              >
                <TabPanel value={selectedTab} index={0} dir={theme.direction}>
                  <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                    <TextField value={outputName} inputProps={{ maxLength: 30 }} label="Dateiname" size='small' style={{width: "400px", alignSelf: "center"}} onChange={(e) => setOutputName(e.target.value)}/>

                    {exportFormat != "kml" ? <Typography component={'span'} style={{textAlign: "center", userSelect: "none"}}>
                      Dateiname zeigen in Karte
                      <Checkbox checked={showFileName} onChange={(e, value) => setShowFileName(value)}></Checkbox>
                    </Typography> : null}

                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Vorlagen</InputLabel>
                      <Select value={"default"} size='small' label="Vorlagen" disabled onChange={(e) => setPaperFormat(e.target.value)}>
                        <MenuItem key={"default"} value={"default"}>-</MenuItem>
                        {Object.entries(paperSize).map((entry) => {
                          return(
                            <MenuItem key={entry[0]} value={entry[0]}>{entry[0]}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>

                    {exportFormat != "kml" ? <FormControl style={{width: "400px", alignSelf: "center"}}>
                      <InputLabel error={scaleTooltipActive}>Maßstab</InputLabel>
                      <Select value={mapScale} style={{width: "400px", alignSelf: "center"}} error={scaleTooltipActive} size='small' label='Maßstab' onChange={(e) => {setMapScale(e.target.value); setScaleTooltipActive(false);}}>
                        {Object.entries(scales).map((entry) => {
                          return(
                            <MenuItem key={entry[0]} value={entry[0]}>{entry[1]}</MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText error={scaleTooltipActive} hidden={!scaleTooltipActive}>Maßstab auf "Benutzerdefiniert" ändern</FormHelperText>
                    </FormControl> : null}

                    {exportFormat != "kml" ? <Tooltip placement='top' title="Markierung / GisStyles unterstützt"><Typography component={'span'} style={{textAlign: "center", userSelect: "none"}}>
                      Legende zeigen in Karte
                      <Checkbox checked={showLegend} onChange={(e, value) => setShowLegend(value)}></Checkbox>
                    </Typography></Tooltip> : null}

                    {exportFormat != "kml" ? <Tooltip placement='top' title="Markierung unterstützt"><Typography component={'span'} style={{textAlign: "center", userSelect: "none"}}>
                      Markierungen gruppieren in Karte
                      <Checkbox checked={clusterIcons} onChange={(e, value) => setClusterIcons(value)}></Checkbox>
                    </Typography></Tooltip> : null}

                    {exportFormat != "kml" ? <Typography component={'span'} style={{textAlign: "center", userSelect: "none"}}>
                      Nordpfeil zeigen in Karte
                      <Checkbox checked={showNorthArrow} onChange={(e, value) => setShowNorthArrow(value)}></Checkbox>
                    </Typography> : null}

                    {exportFormat != "kml" ? <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Format</InputLabel>
                      <Select value={paperFormat} size='small' label="Format" onChange={(e) => setPaperFormat(e.target.value)}>
                        {Object.entries(paperSize).map((entry) => {
                          return(
                            <MenuItem key={entry[0]} value={entry[0]}>{entry[0]}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl> : null}

                    {exportFormat != "kml" ? <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Ausrichtung</InputLabel>
                      <Select value={paperOrientation} style={{width: "400px", alignSelf: "center"}} size='small' label='Ausrichtung' onChange={(e) => setPaperOrientation(e.target.value)}>
                        <MenuItem key={"portrait"} value={"portrait"}>Hochformat</MenuItem>
                        <MenuItem key={"landscape"} value={"landscape"}>Querformat</MenuItem>
                      </Select>
                    </FormControl> : null}

                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Exportformat</InputLabel>
                      <Select value={exportFormat} style={{width: "400px", alignSelf: "center"}} size='small' label='Exportformat' onChange={(e) => setExportFormat(e.target.value)}>
                        <MenuItem key={"geotiff"} value={"geotiff"}>.GeoTIFF</MenuItem>
                        <MenuItem key={"pdf"} value={"pdf"}>.PDF</MenuItem>
                        <MenuItem key={"png"} value={"png"}>.PNG</MenuItem>
                        <MenuItem key={"kml"} value={"kml"} disabled>.KML - coming soon</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index={1} dir={theme.direction}>
                  <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                    <TextField value={outputName} inputProps={{ maxLength: 30 }} label="Dateiname" size='small' style={{width: "400px", alignSelf: "center"}} onChange={(e) => setOutputName(e.target.value)}/>
                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Exportformat</InputLabel>
                      <Select value={exportFormat} style={{width: "400px", alignSelf: "center"}} size='small' label='Exportformat' onChange={(e) => setExportFormat(e.target.value)}>
                        <MenuItem key={"csv"} value={"csv"}>.CSV</MenuItem>
                        <MenuItem key={"excel"} value={"excel"}>.XLSX</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </TabPanel>
                <TabPanel value={selectedTab} index={2} dir={theme.direction}>
                  <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                    <TextField value={outputName} inputProps={{ maxLength: 30 }} label="Dateiname" size='small' style={{width: "400px", alignSelf: "center"}} onChange={(e) => setOutputName(e.target.value)}/>

                    <Typography component={'span'} style={{textAlign: "center"}}>
                      Dateiname zeigen in Karte
                      <Checkbox checked={showFileName} onChange={(e, value) => setShowFileName(value)}></Checkbox>
                    </Typography>
                    
                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Vorlagen</InputLabel>
                      <Select value={"default"} size='small' label="Vorlagen" disabled onChange={(e) => setPaperFormat(e.target.value)}>
                        <MenuItem value={"default"}>Standardbericht</MenuItem>
                        {Object.entries(paperSize).map((entry) => {
                          return(
                            <MenuItem key={entry[0]} value={entry[0]}>{entry[0]}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>

                    <FormControl style={{width: "400px", alignSelf: "center"}}>
                      <InputLabel error={scaleTooltipActive}>Maßstab</InputLabel>
                      <Select value={mapScale} style={{width: "400px", alignSelf: "center"}} error={scaleTooltipActive} size='small' label='Maßstab' onChange={(e) => {setMapScale(e.target.value); setScaleTooltipActive(false);}}>
                        {Object.entries(scales).map((entry) => {
                          return(
                            <MenuItem key={entry[0]} value={entry[0]}>{entry[1]}</MenuItem>
                          )
                        })}
                      </Select>
                      <FormHelperText error={scaleTooltipActive} hidden={!scaleTooltipActive}>Maßstab auf "Benutzerdefiniert" ändern</FormHelperText>
                    </FormControl>

                    <Tooltip placement='top' title="Markierung / GisStyles unterstützt">
                      <Typography component={'span'} style={{textAlign: "center"}}>
                        Legende zeigen in Karte
                        <Checkbox checked={showLegend} onChange={(e, value) => setShowLegend(value)}></Checkbox>
                      </Typography>
                    </Tooltip>

                    <Typography component={'span'} style={{textAlign: "center"}}>
                      Nordpfeil zeigen in Karte
                      <Checkbox checked={showNorthArrow} onChange={(e, value) => setShowNorthArrow(value)}></Checkbox>
                    </Typography>

                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Format</InputLabel>
                      <Select value={paperFormat} size='small' label="Format" onChange={(e) => setPaperFormat(e.target.value)}>
                        {Object.entries(paperSize).map((entry) => {
                          return(
                            <MenuItem key={entry[0]} value={entry[0]}>{entry[0]}</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>

                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Ausrichtung</InputLabel>
                      <Select defaultValue={paperOrientation} style={{width: "400px", alignSelf: "center"}} size='small' label='Ausrichtung' onChange={(e) => setPaperOrientation(e.target.value)}>
                        <MenuItem key={"portrait"} value={"portrait"}>Hochformat</MenuItem>
                        <MenuItem key={"landscape"} value={"landscape"}>Querformat</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{width: "400px", alignSelf: "center"}} >
                      <InputLabel>Exportformat</InputLabel>
                      <Select defaultValue={exportFormat} style={{width: "400px", alignSelf: "center"}} size='small' label='Exportformat' onChange={(e) => setExportFormat(e.target.value)}>
                        <MenuItem key={"pdf"} disabled value={"pdf"}>.PDF - coming soon</MenuItem>
                        <MenuItem key={"docx"} disabled value={"docx"}>.DOCX - coming soon</MenuItem>
                      </Select>
                    </FormControl>

                    <Typography style={{textAlign: "center"}}>
                      Tabellendaten hinzufügen
                      <Checkbox checked={showPoiData} onChange={(e, value) => setShowPoiData(value)}></Checkbox>
                    </Typography>
                  </div>
                </TabPanel>
              </SwipeableViews>
            </div>
            <div style={{width: "1px", height: "100%", backgroundColor: "darkgrey"}}/>
            <div id="printParent" style={{display: "flex", flexDirection: "column", width: "50%", height: "100%", overflow: "hidden", position: "relative"}}>
              <Typography fontWeight={600} style={{textAlign: "center", userSelect: "none"}}>Vorschau</Typography>
              {exportFormat != "kml" && selectedTab != 1 ?
              <div
                style={{
                  height: mapHeight,
                  width: mapWidth,
                  backgroundColor: "white",
                  padding: (selectedTab == 2 ? (mapWidth * 0.00503) + "mm" : 0),
                  margin: "auto",
                  transformOrigin: "top left",
                  transform: "scale(" + sc + ") translate(-50%, -50%)",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  filter: "drop-shadow(0px 10px 20px #00000055)",
                  borderRadius: "8px",
                  overflow: "hidden"
                }}
              >
                <BasicMapExport
                  userState={props.userState}
                  mapScale={mapScale}
                  showFileName={showFileName}
                  fileName={outputName}
                  showLegend={showLegend}
                  showNorthArrow={showNorthArrow}
                  clusterIcons={clusterIcons}
                  initMapPosition={currentMapPos}
                  onMapPositionChanged={onMapPositionChanged}
                  onGeometryChanged={onGeometryChanged}
                  onIsRenderingChanged={(active) => setIsRendering(active)}
                  onDisabledZoomScroll={(active) => setScaleTooltipActive(active)}
                />
              </div> : <Typography style={{textAlign: "center", margin: "auto"}}>Keine Vorschau verfügbar.</Typography>}
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "0px 24px 20px 0px" }}>
          {true ? (
            <Box sx={{ m: 1, position: 'relative' }}>
            <Tooltip title={isRendering ? "Karte wird erstellt..." : ""} placement='top'><span><Button variant="contained" disabled={exportActive || isRendering} onClick={handleFileExport}>{"Erstellen"}</Button></span></Tooltip>
            {exportActive || isRendering && (
              <Tooltip title={isRendering ? "Karte wird erstellt..." : ""} placement='top'>
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              </Tooltip>
            )}
          </Box>
          ) : null}
          <Button style={{ color: "grey" }} onClick={() => setDialogOpen(false)}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExportButton


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (<Box sx={{ p: 3 }}>{children}</Box>)}
    </div>
  );
}