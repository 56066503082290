import "./views/mainView/MainView";
import "./widgets/navBar/NavBarItem.css";

import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";

import { LicenseInfo } from "@mui/x-license";
import Main from "./views/Main";
import Providers from "./context";
import { deDE } from "@mui/x-data-grid/locales";
import { generateOid } from "./js/helper";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations.json";

function App() {

  useEffect(() => {
    let storedDeviceId = localStorage.getItem("deviceId");
    console.log("🚀 ~ useEffect ~ storedDeviceId:", storedDeviceId)
    if (storedDeviceId == undefined) {
      storedDeviceId = generateOid();
      localStorage.setItem("deviceId", storedDeviceId);
    }
  }, []);

  const [themeType, setThemeType] = useState("light");
  const theme = createTheme(
    {
      palette: {
        mode: themeType == "dark" ? "dark" : "light",
        primary: {
          main: "#009900",
        },
        background: {
          default: themeType == "dark" ? "#333" : "#eee",
          paper: themeType == "dark" ? "#232323" : "#ddd",
        },
      },
    },
    deDE
  );

  i18n.use(initReactI18next).init(translations);

  LicenseInfo.setLicenseKey(
    "3d8e4376b989312ca4a07d558a7b65d3Tz05NTg3MSxFPTE3NTQ4MDg3NDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
  );

  return (
    <Providers>
      <ThemeProvider theme={theme}>
        <Main />
      </ThemeProvider>
    </Providers>
  );
}

export default App;
