import React, { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { AddAPhoto } from '@mui/icons-material';

const FileIconButton = ({ onFileSelect, accept }) => {
  // Create a reference for the hidden file input
  const fileInputRef = useRef(null);

  // Function to handle the button click
  const handleButtonClick = () => {
    fileInputRef.current.click();  // Trigger the hidden file input click
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      onFileSelect(event.target.files[0]); // Pass the selected file back to the parent
    }
  };

  return (
    <div>
      <IconButton
        component="span"
        onClick={handleButtonClick}
      >
        <AddAPhoto/>
      </IconButton>
      <input
        type="file"
        accept={accept ? accept : null}
        ref={fileInputRef}
        style={{ display: 'none' }}  // Hide the actual file input
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileIconButton;