import WIV_Icon_Abfuhr_Auftrag from "../assets/tableIcons/WIV_Icon_Abfuhr_Auftrag.svg";
import WIV_Icon_Angebot from "../assets/tableIcons/WIV_Icon_Angebot.svg";
import WIV_Icon_Ansitzeinrichtung from "../assets/tableIcons/WIV_Icon_Ansitzeinrichtung.svg";
import WIV_Icon_Auftrag_Forst_universal from "../assets/tableIcons/WIV_Icon_Angebot.svg";
import WIV_Icon_Fahrlinien from "../assets/tableIcons/WIV_Icon_Fahrlinien.svg";
import WIV_Icon_Grenzpunkt from "../assets/tableIcons/WIV_Icon_Grenzpunkt.svg";
import WIV_Icon_Grundstueck from "../assets/tableIcons/WIV_Icon_Grundstueck.svg";
import WIV_Icon_Habitatbaum from "../assets/tableIcons/WIV_Icon_Habitatbaum.svg";
import WIV_Icon_Holzernte from "../assets/tableIcons/WIV_Icon_Holzernte.svg";
import WIV_Icon_Holzliste from "../assets/tableIcons/WIV_Icon_Holzliste.svg";
import WIV_Icon_Holzpolter from "../assets/tableIcons/WIV_Icon_Holzpolter.svg";
import WIV_Icon_Jagdrevier from "../assets/tableIcons/WIV_Icon_Jagdrevier.svg";
import WIV_Icon_Kontaktperson from "../assets/tableIcons/WIV_Icon_Kontaktperson.svg";
import WIV_Icon_Kontrolle from "../assets/tableIcons/WIV_Icon_Kontrolle.svg";
import WIV_Icon_Kulturbegruendung from "../assets/tableIcons/WIV_Icon_Kulturbegruendung.svg";
import WIV_Icon_Kunden from "../assets/tableIcons/WIV_Icon_Kunden.svg";
import WIV_Icon_Lagerbereich from "../assets/tableIcons/WIV_Icon_Lagerbereich.svg";
import WIV_Icon_Lieferschein from "../assets/tableIcons/WIV_Icon_Lieferschein.svg";
import WIV_Icon_Mitarbeiter_Maschinen from "../assets/tableIcons/WIV_Icon_Mitarbeiter_Maschinen.svg";
import WIV_Icon_Mulcher_Auftrag from "../assets/tableIcons/WIV_Icon_Mulcher_Auftrag.svg";
import WIV_Icon_Orte_von_Interesse from "../assets/tableIcons/WIV_Icon_Orte_von_Interesse.svg";
import WIV_Icon_Pflanzplanung from "../assets/tableIcons/WIV_Icon_Pflanzplanung.svg";
import WIV_Icon_Preise from "../assets/tableIcons/WIV_Icon_Preise.svg";
import WIV_Icon_Rechnung from "../assets/tableIcons/WIV_Icon_Rechnung.svg";
import WIV_Icon_Revier from "../assets/tableIcons/WIV_Icon_Revier.svg";
import WIV_Icon_Revierbuch from "../assets/tableIcons/WIV_Icon_Revierbuch.svg";
import WIV_Icon_Reviereinrichtungen from "../assets/tableIcons/WIV_Icon_Reviereinrichtungen.svg";
import WIV_Icon_Rueckegassen from "../assets/tableIcons/WIV_Icon_Rueckegassen.svg";
import WIV_Icon_Rueckegassen_archiviert from "../assets/tableIcons/WIV_Icon_Rueckegassen_archiviert.svg";
import WIV_Icon_Schadholzmonitoring from "../assets/tableIcons/WIV_Icon_Schadholzmonitoring.svg";
import WIV_Icon_Seilkran from "../assets/tableIcons/WIV_Icon_Seilkran.svg";
import WIV_Icon_Verkehrssicherung from "../assets/tableIcons/WIV_Icon_Verkehrssicherung.svg";
import WIV_Icon_Zeiterfassung from "../assets/tableIcons/WIV_Icon_Zeiterfassung.svg";
import WIV_Icon_Zwangspunkte from "../assets/tableIcons/WIV_Icon_Zwangspunkte.svg";
import WIV_LOGO_2024 from "../assets/wivLogos/WIV_Logo_2024.png";
import WIV_LOGO_2024_CHRISTMAS from "../assets/wivLogos/WIV_Logo_2024_Christmas.png";
import WIV_LOGO_ONLY_2024 from "../assets/wivLogos/WIV_Logo_Only_2024.png";
import WIV_LOGO_ONLY_2024_CHRISTMAS from "../assets/wivLogos/WIV_Logo_Only_2024_Christmas.png";
import { isChristmas } from "./helper";

//PROD DEV - KJB
/*export const GATEWAY_URL = "https://dev.web.wood-in-vision.com";
export const ADMIN_URL = "/admin";*/

//PROD
export const GATEWAY_URL = process.env.BACKEND_URL ?? "https://web.wood-in-vision.com";
export const ADMIN_URL = "/admin";

//LOCAL DEV - KJB
/*export const GATEWAY_URL = "http://localhost:6010";
export const ADMIN_URL = "";*/

export const WIV_LOGO = isChristmas() ? WIV_LOGO_2024_CHRISTMAS : WIV_LOGO_2024;
export const WIV_LOGO_ONLY = isChristmas() ? WIV_LOGO_ONLY_2024_CHRISTMAS : WIV_LOGO_ONLY_2024;

export const frontend_version = "1.0.13";

export const scales = {
  'fit': 'Füllend',
  'custom': 'Benutzerdefiniert',
  ' 500': '1:500',
  ' 1000': '1:1.000',
  ' 2500': '1:2.500',
  ' 5000': '1:5.000',
  ' 10000': '1:10.000',
  ' 15000': '1:15.000',
  ' 20000': '1:20.000',
  ' 25000': '1:25.000',
  ' 50000': '1:50.000',
  ' 75000': '1:75.000',
  ' 100000': '1:100.000',
  ' 250000': '1:250.000',
  ' 500000': '1:500.000',
  ' 1000000': '1:1.000.000'
};

export const paperSize = {
  //'A0': [841,1189],
  //'A1': [594,841],
  //'A2': [420,594],
  'A3': [297,420],
  'A4': [210,297],
  'A5': [148,210]
};

export const muiTypes = {
  "String": "string",
  "Int": "number",
  "Float": "number",
  "SingleList": "singleSelect",
  "Bool": "boolean",
  "Date": "date",
  "DateTime": "dateTime"
}

export const colors = [
  "#ffffff",
  "#7cfc00",
  "#ff4500",
  "#ffd700",
  "#436eee",
  "#ff7f00",
  "#cd00cd",
  "#a6a6a6",
  "#98fb98",
  "#fa8072",
  "#f0e68c",
];

export const suffixes = {
  "application/vnd.google-earth.kml+xml": "kml",
  "application/xml": "xml",
  "text/xml": "xml",
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpeg": "jpg",
  "string": "gpx",
  "string": "kmz",
  "application/pdf": "pdf",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/json": "json",
  "image/gif": "gif",
  "audio/mpeg": "mp3",
  "audio/wav": "wav",
};

//Table Configuration
export const dataTypes = {
  "Label": "Label",
  "Int": "Integer",
  "Float": "Float",
  "String": "Text",
  "MultilineText": "Multiline Text",
  "Email": "Email",
  "PhoneNumber": "Phone Number",
  "Href": "Link",
  "Bool": "Boolean",
  "Archive": "Archive",
  "Button": "Button",

  "Date": "Date",
  "DateTime": "Date/Time",

  "SingleList": "Single Select",
  "MultiList": "Multi Select",
  "DynamicList": "Dynamic List",

  "Location": "Location",
  "Map": "Map",

  "File": "File",
  "Signature": "Signature",
  "Image": "Image",
  
  "NestedTable": "Nested Table",
  "ParentOIds": "Parent OIDs",

  "AssignedUsers" : "Assigned Users"
}

export const tableIcons = [
  WIV_Icon_Abfuhr_Auftrag,
  WIV_Icon_Angebot,
  WIV_Icon_Ansitzeinrichtung,
  WIV_Icon_Auftrag_Forst_universal,
  WIV_Icon_Fahrlinien,
  WIV_Icon_Grenzpunkt,
  WIV_Icon_Grundstueck,
  WIV_Icon_Habitatbaum,
  WIV_Icon_Holzernte,
  WIV_Icon_Holzliste,
  WIV_Icon_Holzpolter,
  WIV_Icon_Jagdrevier,
  WIV_Icon_Kontaktperson,
  WIV_Icon_Kontrolle,
  WIV_Icon_Kulturbegruendung,
  WIV_Icon_Kunden,
  WIV_Icon_Lagerbereich,
  WIV_Icon_Lieferschein,
  WIV_Icon_Mitarbeiter_Maschinen,
  WIV_Icon_Mulcher_Auftrag,
  WIV_Icon_Orte_von_Interesse,
  WIV_Icon_Pflanzplanung,
  WIV_Icon_Preise,
  WIV_Icon_Rechnung,
  WIV_Icon_Revier,
  WIV_Icon_Revierbuch,
  WIV_Icon_Reviereinrichtungen,
  WIV_Icon_Rueckegassen_archiviert,
  WIV_Icon_Rueckegassen,
  WIV_Icon_Schadholzmonitoring,
  WIV_Icon_Seilkran,
  WIV_Icon_Verkehrssicherung,
  WIV_Icon_Zeiterfassung,
  WIV_Icon_Zwangspunkte
]