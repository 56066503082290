import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import CircleStyle from "ol/style/Circle";
import mapBgIcon from "../../assets/tableIcons/mapBGIcon.png";
import kmlMarker from "../../assets/tableIcons/kmlMarker.png";

var gisStyleCaches = {};


//Evaluate Styles
export function evaluateMarkerStyle(feature, resolution, styles, selectedStyle, mapConfigs) {
  //Cluster
  let features = feature.get("features");
  if (features) {
    let text;
    let textSet = false;

    let iconLabelTemplate;
    let iconSrc = mapBgIcon;
    let tableIconBgColor = "#090";
    let uniqueKey = "";
    let oid = "";

    if (features.length > 0) {
      iconSrc = features[0].get("tableIcon");
      tableIconBgColor = features[0].get("tableIconBgColor");
      uniqueKey = features[0].get("layerId");
      oid = features[0].get("guid")?.$oid;
      feature = features[0];
      iconLabelTemplate = features[0].get("iconLabelTemplate");
      if (mapConfigs[uniqueKey]?.showIconsText) {
        text = replaceTemplate(features[0], iconLabelTemplate, false);
      }
    }

    let fillColor = "#000000ff";
    let fillColorSet = false;
    let strokeColor = "#000000ff";
    let strokeColorSet = false;
    let strokeWidth = 2;
    let strokeWidthSet = false;
    let circleD = 2.5;
    let circleDSet = false;

    let textColor = "#000000";
    let textColorSet = false;
    let textBgColor = "#ffffff";
    let textBgColorSet = false;

    let textSize = 16;
    let textSizeSet = false;

    let iconSrcSet = false;

    let foundStyle = styles?.find((element) => element.id == selectedStyle);
    let rules = foundStyle?.styleRules.sort((a, b) => a.order - b.order);
    if (foundStyle == null || (rules?.filter((i) => i.target == "marker").length ?? 0) == 0) {
      return defaultMarkerStyle(features, resolution, tableIconBgColor, iconSrc, text);
    }

    //Use Chached Style
    let gisStyleCache = gisStyleCaches[uniqueKey];
    let style;
    if (gisStyleCache != null) {
      style = gisStyleCache[oid];
      if (style != null) {
        return new Style({
          image: style.iconSrcSet ? 
            new Icon({
              src: style.iconSrc,
              anchor: style.iconSrcSet ? [0.5, 0.5] : [0.5, 1],
              width: style.iconSrcSet ? 24 : 24,
              height: style.iconSrcSet ? 24 : 24
            }) : new CircleStyle({
              radius: pxTomm(style.circleD),
              fill: new Fill({
                color: style.fillColor,
              }),
              stroke: new Stroke({
                color: style.strokeColor,
                width: style.strokeWidth,
              }),
            })
          ,
          text: new Text({
            font: style.textSize + "px Calibri,sans-serif",
            fill: new Fill({
              color: style.textColor,
            }),
            stroke: new Stroke({
              color: style.textBgColor,
              width: 3,
            }),
            offsetY: 5 + pxTomm(circleD),
            textBaseline: "top",
            declutterMode: "declutter",
            text: features.length > 1 ? features.length.toString() : style.text,
          }),
        });
      }
    }

    for (let rule of rules) {
      if(rule.target != "marker") continue;

      if (rule.styleType == "fill_color") {
        if (
          !fillColorSet &&
          evaluateExpression(feature, rule.expression)
        ) {
          fillColor = rule.value;
          fillColorSet = true;
        }
      }

      if (rule.styleType == "stroke_color") {
        if (
          !strokeColorSet &&
          evaluateExpression(feature, rule.expression)
        ) {
          strokeColor = rule.value;
          strokeColorSet = true;
        }
      }

      if (rule.styleType == "stroke_width") {
        if (
          !strokeWidthSet &&
          evaluateExpression(feature, rule.expression)
        ) {
          strokeWidth = rule.value;
          strokeWidthSet = true;
        }
      }

      if (rule.styleType == "circle_d") {
        if (!circleDSet && evaluateExpression(feature, rule.expression)) {
          circleD = rule.value;
          circleDSet = true;
        }
      }

      if (rule.styleType == "font_color") {
        if (
          !textColorSet &&
          evaluateExpression(feature, rule.expression)
        ) {
          textColor = rule.value;
          textColorSet = true;
        }
      }

      if (rule.styleType == "font_bg_color") {
        if (
          !textBgColorSet &&
          evaluateExpression(feature, rule.expression)
        ) {
          textBgColor = rule.value;
          textBgColorSet = true;
        }
      }

      if (rule.styleType == "font_size") {
        if (
          textSizeSet == null &&
          evaluateExpression(feature, rule.expression)
        ) {
          textSize = rule.value;
          textSizeSet = true;
        }
      }

      if (rule.styleType == "label") {
        if (!textSet && evaluateExpression(feature, rule.expression)) {
          text = replaceTemplate(feature, rule.value, false);
          textSet = true;
        }
      }

      if (rule.styleType == "icon_url") {
        if (!iconSrcSet && evaluateExpression(feature, rule.expression)) {
          iconSrc = replaceTemplate(feature, rule.value != null && rule.value != "" && rule.value.includes("://") ? rule.poi.value : "http://www.portal.wood-in-vision.com/runtime/" + rule.poi.value, false);
          iconSrcSet = true;
        }
      }
    }

    //SAVE STYLE TO CACHE
    if (style == null) {
      if (gisStyleCaches[uniqueKey] == null) {
        gisStyleCaches[uniqueKey] = {};
      }
      gisStyleCaches[uniqueKey][oid] = {
        fillColor: fillColor,
        strokeColor: strokeColor,
        strokeWidth: strokeWidth,
        text: text,
        textSize: textSize,
        textColor: textColor,
        textBgColor: textBgColor,

        iconSrc: iconSrc,
        iconSrcSet: iconSrcSet,
        
        circleD: circleD
      };
    }

    //GisStyle Look
    return new Style({
      image: iconSrcSet ? 
        new Icon({
          src: iconSrc,
          anchor: iconSrcSet ? [0.5, 0.5] : [0.5, 1],
          width: iconSrcSet ? 24 : 24,
          height: iconSrcSet ? 24 : 24
        }) : new CircleStyle({
          radius: pxTomm(circleD),
          fill: new Fill({
            color: fillColor,
          }),
          stroke: new Stroke({
            color: strokeColor,
            width: strokeWidth,
          }),
        })
      ,
      text: new Text({
        font: textSize + "px Calibri,sans-serif",
        fill: new Fill({
          color: textColor,
        }),
        stroke: new Stroke({
          color: textBgColor,
          width: 3,
        }),
        offsetY: features.length > 1 ? 5 + pxTomm(circleD) : 10,
        textBaseline: "top",
        declutterMode: "declutter",
        text: features.length > 1 ? features.length.toString() : text,
      }),
    });
  }
}

export function evaluateGeometryStyle(feature, resolution, styles, selectedStyle, mapConfigs) {
  let uniqueKey = feature.get("layerId");
  var lastIndex = uniqueKey.lastIndexOf("_");
  var rawUniqueKey = uniqueKey.slice(0,lastIndex);
  let mapLabelTemplate = feature.get("mapLabelTemplate") ?? "";
  let fillColor = "#00000044";
  let fillColorSet = false;
  let strokeColor = "#000000ff";
  let strokeColorSet = false;
  let strokeWidth = 2;
  let strokeWidthSet = false;
  let text = "";
  if (mapConfigs[uniqueKey.replace("_map", "")]?.showIconsText) {
    text = replaceTemplate(feature, mapLabelTemplate, false).toString();
  }
  let textSet = false;
  let textSize = 16;
  let textColor = "#000000";
  let textColorSet = false;
  let textBgColor = "#ffffffff";
  let textBgColorSet = false;

  let foundStyle = styles?.find((element) => element.id == selectedStyle);
  let rules = foundStyle?.styleRules?.sort((a, b) => a.order - b.order);
  if (foundStyle == null || (rules?.filter((i) => i.target == "map").length ?? 0) == 0) {
    return defaultGeometryStyle(feature,resolution, mapConfigs, rawUniqueKey, text);
  }

  //Use Chached Style
  let gisStyleCache = gisStyleCaches[uniqueKey];
  let style;
  if (gisStyleCache != null) {
    style = gisStyleCache[feature.get("blobGuid")];
    if (style != null) {
      return new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: kmlMarker,
          height: 36,
          color: style.strokeColor
        }),
        text: new Text({
          text: style.text,
          font: style.textSize + "px Calibri,sans-serif",
          fill: new Fill({
            color: style.textColor,
          }),
          stroke: new Stroke({
            color: style.textBgColor,
            width: 3,
          }),
        }),
        stroke: new Stroke({
          color: style.strokeColor,
          width: style.strokeWidth,
        }),
        fill: new Fill({
          color: style.fillColor,
        }),
      });
    }
  }

  //Create new Style when not in Cache
  for (let rule of rules) {
    if(rule.target != "map") continue;

    if (rule.styleType == "fill_color") {
      if (!fillColorSet && evaluateExpression(feature, rule.expression)) {
        fillColor = rule.value;
        fillColorSet = true;
      }
    }

    if (rule.styleType == "stroke_color") {
      if (
        !strokeColorSet &&
        evaluateExpression(feature, rule.expression)
      ) {
        strokeColor = rule.value;
        strokeColorSet = true;
      }
    }

    if (rule.styleType == "stroke_Width") {
      if (
        !strokeWidthSet &&
        evaluateExpression(feature, rule.expression)
      ) {
        strokeWidth = rule.value;
        strokeWidthSet = true;
      }
    }

    if (rule.styleType == "font_Size") {
      if (textSize == null && evaluateExpression(feature, rule.expression)) {
        textSize = rule.value;
      }
    }

    if (rule.styleType == "font_color") {
      if (!textColorSet && evaluateExpression(feature, rule.expression)) {
        textColor = rule.value;
        textColorSet = true;
      }
    }

    if (rule.styleType == "font_bg_color") {
      if (!textBgColorSet && evaluateExpression(feature, rule.expression)) {
        textBgColor = rule.value;
        textBgColorSet = true;
      }
    }

    if (rule.styleType == "label") {
      if (!textSet && evaluateExpression(feature, rule.expression) && mapConfigs[uniqueKey]?.showIconsText) {
        text = replaceTemplate(feature, rule.value, false);
        textSet = true;
      }
    }
  }

  //SAVE STYLE TO CACHE
  if (style == null) {
    if (gisStyleCaches[uniqueKey] == null) {
      gisStyleCaches[uniqueKey] = {};
    }
    gisStyleCaches[uniqueKey][feature.get("blobGuid")] = {
      fillColor: fillColor,
      strokeColor: strokeColor,
      strokeWidth: strokeWidth,
      text: text,
      textSize: textSize,
      textColor: textColor,
      textBgColor: textBgColor,
    };
  }

  return new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: kmlMarker,
      height: 36,
      color: strokeColor
    }),
    text: new Text({
      text: text,
      font: textSize + "px Calibri,sans-serif",
      fill: new Fill({
        color: textColor,
      }),
      stroke: new Stroke({
        color: textBgColor,
        width: 3,
      }),
    }),
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth,
    }),
    fill: new Fill({
      color: fillColor,
    }),
  });
}


//Default Styles
export function defaultMarkerStyle(features, resolution, tableIconBgColor, iconSrc, text) {
  return([
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: mapBgIcon,
        height: 36,
        color: tableIconBgColor
      }),
    }),
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: iconSrc,
        height: 24,
        displacement: [0, 9],
        
      }),
      text: new Text({
        font: "16px Calibri,sans-serif",
        fill: new Fill({
          color: "#000",
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 3,
        }),
        offsetY: features.length > 1 ? -10 : 5,
        textBaseline: "top",
        declutterMode: "declutter",
        text: features.length > 1 ? features.length.toString() : text,
      }),
    })
  ]);
}

export function defaultGeometryStyle(feature, resolution, mapConfigs, uniqueKey, newText) {
  let poiUniqueId = feature.get("layerId");

  let fillColor = feature.get("fill") ?? "#ff000000";
  let strokeColor = feature.get("stroke") ?? "#ff0000";
  let strokeWidth = feature.get("stroke-width") ?? 2;
  let text = newText;
  let textColor = "#000000";
  let textBgColor = "#ffffffff";

  return new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: kmlMarker,
      height: 36,
      color: strokeColor
    }),
    text: new Text({
      text: text,
      font: "16px Calibri,sans-serif",
      fill: new Fill({
        color: textColor,
      }),
      stroke: new Stroke({
        color: textBgColor,
        width: 3,
      }),
    }),
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth,
    }),
    fill: new Fill({
      color: fillColor,
    }),
  });
}


//Helper Functions
function replaceTemplate(feature, stringToPopulate, isExpression) {
  const placeholderRegex = /\${([^}]+)}/g;
  let match;
  let newString = stringToPopulate;

  //Add Listsync replacement

  while ((match = placeholderRegex.exec(stringToPopulate)) !== null) {
    let variable = match[1];
    let defaultValue = "";
    let value;

    //Default Value
    if (variable.includes("=")) {
      let obj = variable.split("=");
      if (obj.length > 1) {
        defaultValue = obj[1];
      }
      value = feature.get(obj[0]);
    } else if (variable.includes(".key")) {
      let obj = variable.split(".key");
      let varName = obj[0];
      value = feature.get(varName);
    } else {
      value = feature.get(variable);
    }

    if (value != null) {
      if (typeof value == "string") {
        newString = newString.replace(match[0], '"' + value + '"');
      } else {
        newString = newString.replace(match[0], value);
      }
    } else {
      newString = newString.replace(match[0], '"' + defaultValue + '"');
    }
  }

  newString = newString
    .replaceAll("<br>", "\n")
    .replaceAll("<br/>", "\n")
    .replaceAll("<br />", "\n")
    .replaceAll("<b>", "")
    .replaceAll("<b/>", "")
    .replaceAll("</b>", "");
  if (!isExpression) {
    newString = newString.replaceAll('"', "");
  }

  return newString;
}

function evaluateExpression(feature, stringToPopulate) {
  let newString = replaceTemplate(feature, stringToPopulate, true);
  let boolean = false;
  if (newString.includes("{")) {
    boolean = false;
  } else {
    try {
      boolean = eval(newString);
    } catch (ex) {
      console.log(ex);
    }      
  }
  return boolean;
}

function calcScreenDPI() {
  const el = document.createElement("div");
  el.style = "width: 1in;";
  document.body.appendChild(el);
  const dpi = el.offsetWidth;
  document.body.removeChild(el);
  return dpi;
}

function pxTomm(px) {
  let dpi = calcScreenDPI();
  let resultPx = (dpi * px) / 25.4;
  return Math.floor(resultPx);
}