import { useEffect } from "react";
import lama from "../assets/lama.gif";
import { isAprilFirst } from "../js/helper";

export default function Lama({height}) {
  let interval;
  var lamaPos = 0;
  var leftToRight = true;

  function moveLama() {
    if(leftToRight) {
      lamaPos += 5;
    } else {
      lamaPos -= 5;
    }
    if(lamaPos < 0) {
      leftToRight = true;
    }
    if((lamaPos+120) > document.documentElement.clientWidth) {
      leftToRight = false;
    }
    let lama = document.getElementById("lama");
    if(lama == null) return;

    lama.style.transform = "translate(" + lamaPos + "px, 0px)"
    
    let lamaGif = document.getElementById("lamaGif");
    if(lamaGif == null) return;

    lamaGif.style.transform = leftToRight ? "scaleX(1)" : "scaleX(-1)";
  }

  useEffect(() => {
    if(isAprilFirst()) {
      interval = setInterval(() => {
        moveLama();
      }, 100);
    }

    return(() => {
      clearInterval(interval);
    });
  }, []);

  return(
    isAprilFirst() ?
      <div id="lama" style={{position: "absolute", bottom: height - 6, left: "70px", overflow: "hidden", userSelect: "none"}}><img id="lamaGif" draggable={false} height={48} src={lama}></img></div>
    :
      null
  )
}