import { useTheme } from '@emotion/react';
import '../../App.css';
import './NavBarItem.css';
import { IconButton } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

function NavBarItem(props) {
  const theme = useTheme();

  if(props.selectedIndex) {
    var style = {width: "66px", height: "70px", backgroundColor: "#333", borderRightStyle: "solid", borderRight: "4px solid", borderColor: theme.palette.primary.main, transition: "all .25s ease", zIndex: 3}
  } else {
    style = null
  }
  return (
    <div className="NavBarItem" style={style} onClick={props.onClick}>
      <IconButton disableRipple style={{margin: "16px"}}>{props.site.icon}</IconButton>
    </div>
  );
}

export default NavBarItem;