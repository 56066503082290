import { ADMIN_URL, GATEWAY_URL } from "../js/defines";

import Cookies from "universal-cookie";
import dayjs from "dayjs";
import { useAuth } from "../providers/AuthProvider";

const cookies = new Cookies();

export function useRefreshToken() {
  const { setUserState } = useAuth(); // Now it's inside a hook

  const refreshAccessToken = async () => {
    const url = GATEWAY_URL + ADMIN_URL + "/api/Auth/refresh-token";
    const user = cookies.get("user");
    const refreshToken  = cookies.get("refreshToken"); 
    const userId  = cookies.get("userId"); 

    if (!refreshToken || !userId) {
      console.warn("No refresh token found. User might be logged out.");
      return null;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          refreshToken: refreshToken,
          userId: userId, // Ensure this matches your backend requirements
        }),
      });

      if (!response.ok) {
        alert("Session expired. Please log in again.");
         cookies.remove("user"); 
         cookies.remove("userId"); 
         cookies.remove("refreshToken"); 
        window.location.reload();
        return null;
      }

      const updatedUser = await response.json();

      // Save to cookies
      cookies.set("user", updatedUser, { path: "/", expires: dayjs(updatedUser.expiresIn).toDate() });

      // Update AuthContext state
      setUserState(updatedUser);

      return  updatedUser;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  };

  return refreshAccessToken;
}